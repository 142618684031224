<template>
  <div>
    <b-row>
      <b-col lg="12">
        <div
          class="card card-custom gutter-b card-stretch card-shadowless bg-light"
        >
          <!--begin::Header-->
          <div class="card-header border-0 pt-5">
            <h3 class="card-title align-items-start flex-column">
              <span class="card-label font-weight-bolder text-dark">
                Message history
              </span>
            </h3>
          </div>
          <!--end::Header-->
          <!--begin::Body-->
          <div class="card-body pt-3 pb-0">
            <!--begin::Table-->

            <b-row align-v="end">
              <b-col lg="5">
                <label for="fromDatePicker">From</label>
                <b-form-datepicker
                  v-model="fromDate"
                  id="fromDatePicker"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>

              <b-col lg="5">
                <label for="toDatePicker">To</label>
                <b-form-datepicker
                  v-model="toDate"
                  id="toDatePicker"
                  class="mb-2"
                ></b-form-datepicker>
              </b-col>

              <b-col lg="2">
                <b-button
                  id="fetchButton"
                  class="btn btn-primary fetch-button mb-2"
                  @click="updateData"
                  block
                  variant="primary"
                >
                  Fetch
                </b-button>
              </b-col>
            </b-row>

            <!-- <b-row class="mt-10">
              <b-col>
                Records per page
                <b-dropdown
                  id="records-per-page-dropdown"
                  :text="recordsPerPage.toString()"
                  variant="transparent"
                  @toggle="recordsPerPageChanged"
                >
                  <b-dropdown-item>20</b-dropdown-item>
                  <b-dropdown-item>50</b-dropdown-item>
                  <b-dropdown-item>250</b-dropdown-item>
                </b-dropdown>
              </b-col>
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :per-page="recordsPerPage"
                  :total-rows="totalRecords"
                  @change="pageChange($event)"
                  align="right"
                ></b-pagination>
              </b-col>
            </b-row> -->

            <!-- <b-row class="mt-10" align-h="end">
              <b-col cols="4">
                <b-form inline align-h="end">
                  <label class="mr-5" for="recordsPerPageLabel"
                    >Records per page</label
                  >
                  <b-form-select
                    id="records-per-page-dropdown"
                    v-model="selectedRecordsPerPage"
                    :options="recordsPerPageOptions"
                    @change="recordsPerPageChangved"
                  >
                  </b-form-select>
                </b-form>
              </b-col>
              <b-col cols="4">
                <b-pagination
                  v-model="currentPage"
                  :per-page="recordsPerPage"
                  :total-rows="totalRecords"
                  @change="pageChange($event)"
                  align="right"
                ></b-pagination>
              </b-col>
            </b-row> -->

            <b-row class="mt-10">
              <b-col cols="12" align-h="end">
                <b-form
                  inline
                  class="mr-0"
                  style="display: flex; justify-content: flex-end"
                >
                  <label class="mr-5" for="recordsPerPageLabel"
                    >Records per page</label
                  >
                  <b-form-select
                    id="records-per-page-dropdown"
                    v-model="selectedRecordsPerPage"
                    :options="recordsPerPageOptions"
                    @change="recordsPerPageChanged"
                  >
                  </b-form-select>
                  <b-pagination
                    v-model="currentPage"
                    :per-page="recordsPerPage"
                    :total-rows="totalRecords"
                    @change="pageChange($event)"
                    align="right"
                    class="ml-10 mb-0"
                  ></b-pagination>
                </b-form>
              </b-col>
            </b-row>

            <b-table responsive :fields="fields" :items="messageHistories">
              <template #table-colgroup>
                <col key="showDetails" style="width:5%" />
                <col key="notificationTime" style="width:10%" />
                <col key="userName" style="width:15%" />
                <col key="sendLogCaption" style="width:25%" />
                <col key="smsMessage" style="width:30%" />
                <col key="smsCount" style="width:10%" />
                <col
                  key="sendResult"
                  style="width:5%"
                  class="label label-inline font-weight-bold label-light-success"
                />
              </template>

              <template #cell(showDetails)="row">
                <b-button
                  size="sm"
                  @click="row.toggleDetails"
                  class="mr-2 h4"
                  variant="link"
                >
                  <!-- {{ row.detailsShowing ? "Hide" : "Show" }} Details -->
                  <b-icon
                    :icon="
                      row.detailsShowing ? 'arrow-up-short' : 'arrow-down-short'
                    "
                  />
                </b-button>
              </template>

              <template #cell(sendResult)="data">
                <span
                  class="label label-inline font-weight-bold"
                  v-bind:class="{
                    'label-light-success': data.value == 'OK',
                    'label-light-danger': data.value !== 'OK',
                  }"
                >
                  {{ data.value }}
                </span>
              </template>

              <template #row-details="row">
                <b-card>
                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>Sender:</b></b-col>
                    <b-col>{{ row.item.sendLogCaption }}</b-col>
                  </b-row>

                  <b-row class="mb-2">
                    <b-col sm="3" class="text-sm-right"><b>Message:</b></b-col>
                    <b-col>{{ row.item.smsMessage }}</b-col>
                  </b-row>
                </b-card>
              </template>
            </b-table>

            <b-row class="mt-10">
              <b-col>
                <b-pagination
                  v-model="currentPage"
                  :per-page="recordsPerPage"
                  :total-rows="totalRecords"
                  @change="pageChange($event)"
                  align="right"
                ></b-pagination>
              </b-col>
            </b-row>

            <b-row class="mb-5">
              <b-col class="text-muted">
                {{ messageCountLabel }} messages displayed
              </b-col>
            </b-row>
          </div>
          <!--end::Body-->
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style></style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      fromDate: this.getLastWeek(),
      toDate: this.getToday(),
      fields: [
        { key: "showDetails", label: "" },
        { key: "notificationTime", label: "Date", formatter: "formatDate" },
        { key: "userName", label: "Sender", formatter: "trimEmail" },
        {
          key: "sendLogCaption",
          label: "Recipients",
          formatter: (value) => {
            return this.shortenValue(value, 80);
          },
        },
        { key: "smsMessage", label: "Message" },
        { key: "smsCount", label: "SMS count" },
        { key: "sendResult", label: "Result" },
      ],
      currentPage: 1,
      totalRecords: null,
      messageHistories: [],
      selectedRecordsPerPage: 10,
      recordsPerPageOptions: [
        { value: 10, text: "10" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
        { value: 250, text: "250" },
      ],
    };
  },

  components: {},

  computed: {
    picture() {
      return (
        process.env.BASE_URL + "media/svg/avatars/avatar-single-orange.svg"
      );
    },
    totalRows() {
      return this.messageHistories.length;
    },
    requestUrl() {
      let adjustedToDate = new Date(this.toDate + " 00:00");
      adjustedToDate.setDate(adjustedToDate.getDate() + 1);
      let queryString = "sentMessages?";
      queryString += `RecordsPerPage=${this.recordsPerPage}&PageNumber=${this.currentPage}`;
      queryString += `&From=${new Date(this.fromDate + " 00:00")
        .toISOString()
        .replace("Z", "")}`;
      queryString += `&To=${adjustedToDate.toISOString().replace("Z", "")}`;
      return queryString;
    },
    recordsPerPage() {
      return this.selectedRecordsPerPage;
    },
    messageCountLabel() {
      let label;
      if (this.messageHistories.length === 0) {
        label = "No";
      } else if (this.messageHistories.length === this.totalRecords) {
        label = "All " + this.totalRecords;
      } else {
        label = this.messageHistories.length + "/" + this.totalRecords;
      }
      return label;
    },
  },

  methods: {
    pageChange(event) {
      this.currentPage = event;
      this.updateData();
    },
    getHistories() {
      ApiService.query(this.requestUrl).then((response) => {
        this.totalPages = response.data.totalPages;
        this.totalRecords = response.data.totalRecords;
        this.messageHistories = response.data.data.sort((a, b) =>
          a.notificationTime < b.notificationTime ? 1 : -1
        );
      });
    },
    getLastWeek() {
      let today = new Date();
      let lastWeek = new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        today.getDate() - 7
      );

      return `${lastWeek.getFullYear()}-${lastWeek.getMonth()}-${lastWeek.getDate()}`;
    },
    getToday() {
      let today = new Date();

      return `${today.getFullYear()}-${today.getMonth() +
        1}-${today.getDate()}`;
    },
    updateData() {
      this.getHistories();
    },
    shortenValue(value, length) {
      return value.substring(0, length) + (value.length > length ? "..." : "");
    },
    trimEmail(value) {
      return value.substring(0, value.indexOf("@"));
    },
    formatDate(value) {
      // we hack the UTC conversation as the reply is in UTC but doesn't have a trailing Z
      return new Date(value + "Z").toLocaleString("en-AU").replace(",", "\n");
    },
    recordsPerPageChanged(e) {
      this.updateData();
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" },
    ]);

    this.getHistories();
  },
};
</script>
